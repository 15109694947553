@import "~bootstrap/dist/css/bootstrap.min.css";
@import "styles.min.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~toastr";

::-webkit-scrollbar-track {
    background-color: #354463
}
::-webkit-scrollbar {
    width: 6px;
    background-color: #c1f7d5;
}
::-webkit-scrollbar-thumb {
    background:#c1f7d5;
}
::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 100px 20px #c1f7d5;
}

.main-menu__item.main-menu__item--has-child.menu-profile {
    height: 100%;
    .profile-button {
        background-color: #c1f7d5;
        color: #354463;
        text-decoration: none;
        border-radius: 0;
        padding: 0 30px 0 30px;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .main-menu__sub-list.sub-list--style-2 {
        left: 0;
        width: 100%;
        padding: 0;
        li {
            margin: 0;
            a {
                display: block;
                padding: 18px 35px;
                &:after {
                    content: none;
                }
                &:before {
                    content: none;
                }
                &:hover {
                    background: white;
                    color: #354463;
                }
            }
            &.separator {
                border-bottom: 1px solid #c1f7d5;
            }
        }
    }
}

.block-video-bg {
    overflow-y: hidden;
    video{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .video-background-container{
        background-color: #0A1F2B;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.9;
    }
}

section.blog {
    .container {
        max-width: 800px;
    }
}

section#login {
    position: relative;
    height: 100vh;

    .bg-form {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -345px;
        margin-top: -345px;
    }
    .login-wrapper {
        max-width: 800px;
        margin: auto;
        padding: 30vh 8vw;
        position: relative;
    }
    .login-block {
        background: rgba(255, 255, 255, 0.7);
        padding: 40px;
        .alert.alert-danger {
            padding: 5px 30px;
            text-align: center;
            border-radius: 15px;
        }
        .error-form {
            background: #ff7979;
            padding: 10px;
            ul {
                margin: 0;
                padding: 0;
                li {
                    color: white;
                    list-style: none;
                }
            }
        }

        button[type='submit'] {
            margin-top: 20px;
            width: 100%;
        }
    }
}
